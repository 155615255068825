import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import NorthAmerica from "../icon/northAmerica"
import { Col, Container, Row } from "react-bootstrap"

const ContactLocations = () => {
  return (
    <section className="location-info">
      <Container>
        <Row>
            <Col lg={4} className="mb-5 mb-lg-0">
                <div className="location">
                    <StaticImage src="../../images/contact/usa.png" alt="WowMakers USA" placeholder="blurred" width={536} height={300}/>
                    <div className="location-details">
                        <h3 className="name d-flex align-items-center">
                        <NorthAmerica />
                        <span>North America</span>
                        </h3>
                        <div className="address">
                            <p className="branch">USA</p>
                            <p className="content india">
                                c/o JSJ Research, <br />2080 Sugarloaf Pkwy Suit 200, <br />Lawrenceville, GA 30045, United States
                            </p>
                            <p className="branch">Canada</p>
                            <p className="content">
                                #3665 Kingsway- Suite 300 <br />Vancouver BC, V5R 5W2
                            <br />Canada
                            </p>
                        </div>
                    </div>
                </div>
            </Col>
            <Col lg={4} className="mb-5 mb-lg-0">
                <div className="location">
                    <StaticImage src="../../images/contact/singapore.png" alt="WowMakers Singapore" placeholder="blurred" width={536} height={300}/>
                    <div className="location-details">
                        <h3 className="name d-flex align-items-center">
                        <NorthAmerica />
                        <span>Singapore</span>
                        </h3>
                        <div className="address">
                            <p className="content">
                                80B, #12-05, Bencoolen Street <br />The Bencoolen, Singapore <br />Postal Code: 189648
                            </p>
                        </div>
                    </div>
                </div>
            </Col>
            <Col lg={4}>
                <div className="location">
                    <StaticImage src="../../images/contact/india.png" alt="WowMakers India" placeholder="blurred" width={536} height={300}/>
                    <div className="location-details">
                        <h3 className="name d-flex align-items-center">
                        <NorthAmerica />
                        <span>India</span>
                        </h3>
                        <div className="address">
                            <p className="branch">BANGALORE</p>
                            <p className="content india">
                                53, 35th Main Rd, Someshwara
                                Colony, Stage 2, BTM Layout,
                                Bengaluru, Karnataka -560068
                            </p>
                            <p className="branch">KOCHI</p>
                            <p className="content">
                                #269AB1, Near Cochin SEZ
                                Chittethukara, Kakkanad
                                Kochi, Kerala - 682037

                            </p>
                        </div>
                    </div>
                </div>
            </Col>                        
        </Row>
      </Container>
    </section>
  )
}

export default ContactLocations
