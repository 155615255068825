import React from "react";

function NorthAmerica() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      width="90"
      height="90"
      enableBackground="new 0 0 150 150"
      version="1.1"
      viewBox="0 0 150 150"
      xmlSpace="preserve"
    >
      <circle cx="75" cy="75" r="75" fill="#FCF112" className="st0"></circle>
      <path d="M88.6 55.3c0-3.7-1.5-7.1-4-9.6-2.4-2.4-5.8-4-9.6-4-3.7 0-7.1 1.5-9.6 4-2.4 2.4-4 5.8-4 9.6 0 3.7 1.5 7.1 4 9.6 2.4 2.4 5.8 4 9.6 4 3.7 0 7.1-1.5 9.6-4 2.5-2.4 4-5.9 4-9.6zM75.1 65c-5.3 0-9.6-4.3-9.6-9.6s4.3-9.6 9.6-9.6 9.6 4.3 9.6 9.6c0 5.2-4.4 9.6-9.6 9.6z"></path>
      <path d="M55 75.5l.2.2 19.9 20.9 19.7-20.8.1-.1.4-.4.4-.4c4.8-5.2 7.4-11.9 7.4-18.9 0-7.7-3.1-14.7-8.2-19.7-5.1-5.1-12-8.2-19.7-8.2s-14.7 3.1-19.7 8.2c-5.1 5.1-8.2 12-8.2 19.7 0 7 2.6 13.7 7.4 18.9l.3.6zM51.2 56c0-13.1 10.6-23.8 23.8-23.8C88.2 32.2 98.9 43 98.9 56c0 5.6-2 11-5.6 15.3l-.9 1c-.1.2-.3.3-.4.4L75.1 90.6l-17-17.8-.4-.4-.6-.9H57l-.1-.1c-3.7-4.3-5.7-9.8-5.7-15.4z"></path>
      <path d="M101.7 79.5c-.1.3-.2.6-.2 1 0 .9.5 1.7 1.3 2.1 3.7 1.7 6.6 3.7 8.7 5.9 2 2.2 3.1 4.6 3.1 7 0 2.3-1 4.6-2.9 6.7s-4.6 4.1-8.1 5.8c-6.9 3.3-16.8 5.6-28.5 5.6-17.7 0-31-5.2-36.6-11.6-1.9-2.1-2.9-4.4-2.9-6.7 0-2.4 1.1-4.8 3.1-7s4.9-4.2 8.6-5.9c.8-.4 1.3-1.2 1.3-2.1 0-.3-.1-.6-.2-1-.4-.8-1.2-1.3-2.2-1.3-.3 0-.6.1-1 .2-4.7 2.2-8.3 4.8-10.7 7.7-2.4 2.9-3.7 6-3.7 9.3 0 6.3 4.7 12 12.7 16.1 7.9 4.1 19 6.7 31.4 6.7s23.5-2.5 31.5-6.7 12.7-9.8 12.7-16.1c0-3.3-1.3-6.5-3.7-9.4-2.4-2.9-6.1-5.5-10.8-7.6-.3-.1-.6-.2-1-.2-.6.1-1.5.6-1.9 1.5z"></path>
    </svg>
  );
}

export default NorthAmerica;