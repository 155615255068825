import React from "react"
import MainLogo from "../global/mainLogo"
import { Col, Container, Row } from "react-bootstrap"

import "../../sass/global/main.scss"

const ContactMain = () => {
  return (
    <div className="main">
      <Container>
        <Row>
          <Col md={12} lg={7} xl={5}>
            <div className="intro">
              <p className="service-label">CONTACT US</p>
              <h1 className="title">Hello! We've been expecting you.</h1>
              <p className="description">
              Now that you’re here, let’s start a conversation. Send us a message, chat with us or pick up the phone and call us directly. We will guarantee you exceptional service we’d want to experience ourselves.
              </p>
            </div>
          </Col>
          <MainLogo />
        </Row>
      </Container>
    </div>
  )
}

export default ContactMain
