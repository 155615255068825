import React from "react"
import { Col, Row } from "react-bootstrap"
import '../../sass/global/mainlogo.scss'

const MainLogo = () => {
    return (
        <Col md={12} className="header-client-logo">
            <Row>
                <Col md={4} lg={2} sm={4} xs={6} className="cl-header pb-lg-0 pb-3">
                    <img src="/main-logo/microsoft.svg" class="hc-logo" width="150" height="35" alt="WowMakers Client Microsoft"/>
                </Col>
                <Col md={4} lg={2} sm={4} xs={6} className="cl-header pb-lg-0 pb-3">
                    <img src="/main-logo/mercedez.svg" class="hc-logo" width="150" height="35" alt="WowMakers Client Microsoft"/>
                </Col>
                <Col md={4} lg={2} sm={4} xs={6} className="cl-header pb-lg-0 pb-3">
                    <img src="/main-logo/citibank.svg" class="hc-logo" width="150" height="35" alt="WowMakers Client Microsoft"/>
                </Col>
                <Col md={4} lg={2} sm={4} xs={6} className="cl-header pb-lg-0 pb-3">
                    <img src="/main-logo/vodafone.svg" class="hc-logo" width="150" height="35" alt="WowMakers Client Microsoft"/>
                </Col>
                <Col md={4} lg={2} sm={4} xs={6} className="cl-header">
                    <img src="/main-logo/tencent.svg" class="hc-logo" width="150" height="35" alt="WowMakers Client Microsoft"/>
                </Col>
                <Col md={4} lg={2} sm={4} xs={6} className="cl-header">
                    <img src="/main-logo/japan.svg" class="hc-logo" width="150" height="35" alt="WowMakers Client Microsoft"/>
                </Col>                                                                                
            </Row>
        </Col>
    )
}

export default MainLogo
