import React from "react"
import SeoHead from "../../components/global/seoHead"
import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"
import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"
import ContactMain from "../../components/contact/contactMain"
import ContactForm from "../../components/contact/contactForm"
import DirectContact from "../../components/global/directContact"
import ContactTalk from "../../components/contact/contactTalk"
import ContactLocations from "../../components/contact/contactLocations"

import ContactFeaturedImage from "../../../static/home/wowmakers.jpeg"

import "../../sass/pages/contact.scss"

export const Head = () => (
    <>
      <SeoHead
        ogImage={ContactFeaturedImage}
        title="Let’s talk | WowMakers"
        description="We're a 12-year old UI/UX design company specialized in user experience design (UX), user interface design (UI) & mobile and web engineering."
      />
    </> 
)

const Contact = () => {
    return(
        <Layout>
        <Seo
            bclass="contact"
            bid="contact"
        ></Seo>
        <ScrollIndicationHeightZero />
        <ContactMain />
        <ContactForm />
        <DirectContact />
        <ContactTalk />
        <ContactLocations />
        </Layout>
    )
}

export default Contact