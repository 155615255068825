import React from "react";
import { navigate } from "gatsby";
import emailjs from '@emailjs/browser';
import ButtonIcon from '../icon/buttonIcon'
import { Row, Col, Form } from 'react-bootstrap'

const WowForm = ({ pname }) => {     

    const yourSubmitFunction=e=>{
        e.preventDefault();
        var name = e.target['name'].value;
        var email = e.target['email'].value;
        var phone = e.target['mobile'].value;
        var find_us = e.target['find_us'].value;
        var message = e.target['message'].value;
        var user_exp_design = '';
        var exp_video = '';
        var ui_ux_design = '';
        if(document.getElementById("services-1").checked === true) {
            user_exp_design = e.target['looking_for_user_experience_design'].value;
        } else {
            user_exp_design = '';
        }
        if(document.getElementById("services-2").checked === true) {
            exp_video =  e.target['looking_for_expaliner_video'].value;
        } else {
            exp_video = '';
        }
        if(document.getElementById("services-3").checked === true) {
            ui_ux_design = e.target['looking_for_mobile_and _web_ui_ux_design'].value;
        } else {
            ui_ux_design = '';
        }               
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        var validNum = /^[+0-9]+$/;
        var emailvalid = '';
        var phonevalid = '';
        var namevalid = '';
        var nameId = document.getElementById("formBasicName");
        var emailId = document.getElementById("formBasicEmail");
        var phoneId = document.getElementById("formBasicPhone");
        if(email.match(validRegex)) {
            emailvalid = true;
            emailId.classList.remove("not-valid");
        }else {
            emailvalid = false;
            emailId.classList.add("not-valid");
        }

        if(phone.match(validNum)) {
            phonevalid = true;
            phoneId.classList.remove("not-valid");
        }else {
            phonevalid = false;
            phoneId.classList.add("not-valid");
        }

        if(name === '') {
            namevalid = false;
            nameId.classList.add("not-valid");
        } else {
            nameId.classList.remove("not-valid");
            namevalid = true;
        }

        if(namevalid === true && emailvalid === true && phonevalid === true) {
            var looking='';
            var ui_sep = '';
            var ui_ux_sep = '';
            if (user_exp_design !== '') {
                ui_sep = ', ';
                ui_ux_sep =", ";
                looking = user_exp_design;
              }
            
              if (exp_video !=='') {
                 ui_ux_sep =", ";
                 looking = looking+ui_sep+exp_video;
              }
            
              if (ui_ux_design !=='') {
                looking = looking+ui_ux_sep+ui_ux_design;
              }
    
            var new_contact = {
                'First name': name,
                'Emails': email,
                'Mobile': phone,
                'How did you find us?': find_us,
                'Services': looking,
                'Message': message
            };
            var rurl = '/thank-you/?page='+pname
            emailjs.send('service_ho3u6r6', 'template_hhsbgf2', new_contact, 'Qc6LQ_WyoVPpcjEcx')
            .then(function(response) {
               console.log('SUCCESS!', response.status, response.text);
            }, function(error) {
               console.log('FAILED...', error);
            }); 
            navigate(rurl);            
        } else {
            console.log('error');
        }


    }

    const nameValid=e=> {
        var name = e.target.value;
        var nameId = document.getElementById("formBasicName");
        if(name === '') {
            nameId.classList.add("not-valid");
        } else {
            nameId.classList.remove("not-valid");
        }        
    }

    const emailValid=e=> {
        var email = e.target.value;
        var emailId = document.getElementById("formBasicEmail");
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if(email.match(validRegex)) {
            emailId.classList.remove("not-valid");
        }else {
            emailId.classList.add("not-valid");
        }        

    }
    
    const phoneValid=e=> {
        var phone = e.target.value;
        var phoneId = document.getElementById("formBasicPhone");
        var validNum = /^[+0-9]+$/;

        if(phone.match(validNum)) {
            phoneId.classList.remove("not-valid");
        }else {
            phoneId.classList.add("not-valid");
        }        
    }    

    return(
        <div className="form-wrapper mt-5">
            <Form className="wowForm" id="form" method="POST" onSubmit={yourSubmitFunction} >
                <Row>
                    <Col lg={6} className="mb-4">
                        <Form.Group controlId="formBasicName">
                            <Form.Label className="d-none">Your Name *</Form.Label>
                            <Form.Control onchange={nameValid} className="formname" name="name" type="text" placeholder="Your Name *"/>
                        </Form.Group>
                    </Col>
                    <Col lg={6} className="mb-4">
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label className="d-none">Email</Form.Label>
                            <Form.Control onchange={emailValid} type="text" className="formemail" name="email" placeholder="Please enter your email*"/>
                        </Form.Group>
                    </Col>
                    <Col lg={6} className="mb-4">
                        <Form.Group controlId="formBasicPhone">
                            <Form.Label className="d-none">Contact Number</Form.Label>
                            <Form.Control type="text"  onchange={phoneValid} className="formmobile" name="mobile" placeholder="Contact number*"/>
                        </Form.Group>
                    </Col>
                    <Col lg={6} className="mb-4">
                        <Form.Group controlId="formBasicSelect">
                            <Form.Label className="d-none">How did you find us?</Form.Label>
                            <Form.Control as="select" name="find_us">
                                <option value="">How did you hear about WowMakers?</option>
                                <option value="Friend or Business Referral">Friend or Business Referral</option>
                                <option value="Google">Google</option>
                                <option value="Facebook">Facebook</option>
                                <option value="LinkedIn">LinkedIn</option>
                                <option value="Twitter">Twitter</option>
                                <option value="Press Article">Press Article</option>
                                <option value="Other">Other</option>
                            </Form.Control>                                     
                        </Form.Group>
                    </Col>
                    <Col lg={12} className="mb-4">
                        <Row>
                            <Col lg={4} className="checkbox2button mb-lg-0 mb-4">
                            <label className="checkbox-label checked">User Experience Design
                                <input id="services-1" class="services-checkbox" type="checkbox" name="looking_for_user_experience_design" value="User Experience Design" checked="true"/>
                                <span class="checkmark"></span>
                            </label>
                            </Col>
                            <Col  lg={4} className="checkbox2button mb-lg-0 mb-4">
                                <label className="checkbox-label">Explainer Video
                                    <input class="services-checkbox" id="services-2" type="checkbox" name="looking_for_expaliner_video" value="Explainer Video"/>
                                    <span class="checkmark"></span>
                                </label>
                            </Col>
                            <Col  lg={4} className="checkbox2button">
                                <label className="checkbox-label">Software Development
                                    <input id="services-3" class="services-checkbox" type="checkbox" name="looking_for_mobile_and _web_ui_ux_design"  value="Software Development" />
                                    <span class="checkmark"></span>
                                </label>
                            </Col>                                                                                        
                        </Row>
                    </Col>
                    <Col lg={12} className="mb-4">
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label className="d-none">Example textarea</Form.Label>
                            <Form.Control as="textarea" name="message" rows={6} placeholder="Your Message" />
                        </Form.Group>
                    </Col>
                    <Col lg={12}>
                        <button type="submit" className="wowaction-btn">
                            <ButtonIcon />
                            <span>Send Message</span>
                        </button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default WowForm