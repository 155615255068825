import React from 'react'
import { Container, Row, Col} from 'react-bootstrap'
import WowForm from '../global/wowForm'
import '../../sass/global/form.scss'


const ContactForm = () => {
    
    return(
        <section className='form-main contact'>
            <Container>
                <Row className='justify-content-center'>
                    <Col lg={8}>
                        <h2 className='text-center sub-title'>Thank you for your interest in WowMakers! How can we help you?</h2>                        
                        <WowForm pname="contact"/>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default ContactForm